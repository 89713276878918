"use strict";
import { Fragment, jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOMClient from "react-dom/client";
import ReactClient from "react-server-dom-webpack/client.browser";
import { ErrorBoundary } from "./lib/error-boundary";
import { $__global } from "./lib/global";
import { setupBrowserRouter } from "./lib/router/browser";
import GlobalErrorPage from "./routes/global-error";
import "./style.css?url";
import "./lib/client-references-browser.js";
async function main() {
  const url = new URL(window.location.href);
  if (url.searchParams.has("__nojs")) {
    return;
  }
  let $__setFlight;
  const callServer = async (id, args) => {
    const url2 = new URL(window.location.href);
    url2.searchParams.set("__f", "");
    url2.searchParams.set("__a", id);
    const flight = ReactClient.createFromFetch(
      fetch(url2, {
        method: "POST",
        body: await ReactClient.encodeReply(args)
      }),
      { callServer }
    );
    $__setFlight(flight);
    return (await flight).actionResult;
  };
  $__global.__f_call_server = callServer;
  const initialFlight = ReactClient.createFromReadableStream(
    self.__flightStream,
    { callServer }
  );
  function BrowserRoot() {
    const [flight, setFlight] = React.useState(initialFlight);
    React.useEffect(() => {
      $__setFlight = (flight2) => React.startTransition(() => setFlight(flight2));
      return setupBrowserRouter(() => {
        const url2 = new URL(window.location.href);
        url2.searchParams.set("__f", "");
        $__setFlight(
          ReactClient.createFromFetch(fetch(url2), {
            callServer
          })
        );
      });
    }, []);
    return /* @__PURE__ */ jsx(Fragment, { children: React.use(flight).node });
  }
  let browserRoot = /* @__PURE__ */ jsx(ErrorBoundary, { Fallback: GlobalErrorPage, children: /* @__PURE__ */ jsx(BrowserRoot, {}) });
  if (!url.searchParams.has("__nostrict")) {
    browserRoot = /* @__PURE__ */ jsx(React.StrictMode, { children: browserRoot });
  }
  if (self.__nossr) {
    ReactDOMClient.createRoot(document).render(browserRoot);
  } else {
    const formState = (await initialFlight).actionResult;
    React.startTransition(() => {
      ReactDOMClient.hydrateRoot(document, browserRoot, {
        formState
      });
    });
  }
  if (__define.DEV) {
    const { client } = await import("webpack-dev-server/client/socket.js");
    client.onMessage((data) => {
      const message = JSON.parse(data);
      if (message.type === "custom:update-server") {
        window.location.reload();
      }
    });
  }
}
main();
