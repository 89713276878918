"use strict";
"use client";
import { jsx, jsxs } from "react/jsx-runtime";
export default function GlobalErrorPage() {
  return /* @__PURE__ */ jsx("html", { children: /* @__PURE__ */ jsxs(
    "body",
    {
      style: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      children: [
        /* @__PURE__ */ jsx("h3", { children: "Something went wrong" }),
        /* @__PURE__ */ jsxs("div", { children: [
          "Back to ",
          /* @__PURE__ */ jsx("a", { href: "/", children: "Home" })
        ] })
      ]
    }
  ) });
}
